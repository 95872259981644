















































import IDayEvent from '@/modules/events/interfaces/day-event.interface';
import { ValidationError } from 'class-validator';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import EventsModel from '@/modules/events/models/events.model';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import ViewEventTitle from '@/modules/events/components/events-manager/view-edit-popup/view-event-title.vue';
import ViewEventForm from '@/modules/events/components/events-manager/view-edit-popup/view-event-form.vue';
import AddEditEventForm from '@/modules/events/components/events-manager/view-edit-popup/add-edit-event-form.vue';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';

@Component({
    components: {
        ModalWrapper,
        LoaderWrapper,
        ViewEventForm,
        AddEditEventForm,
        ViewEventTitle,
    },
})
export default class ViewEditEventPopup extends Vue {
    @Inject(EventsManagerServiceS) private eventsManagerService!: EventsManagerService;

    @Prop({ required: false })
    private eventId?: string;

    errors: ValidationError[] = [];

    get isView(): boolean {
        return this.$route.fullPath.includes('view-event');
    }

    get isEdit(): boolean {
        return this.$route.fullPath.includes('edit-event');
    }

    get isNew(): boolean {
        return this.$route.fullPath.includes('new-event');
    }

    get event(): IDayEvent | null {
        return this.eventId ? this.eventsManagerService.getDayEventById(this.eventId) : null;
    }

    async saveEvent(event: EventsModel) {
        let newEventId = '';
        if (event.id) {
            if (!event?.marketId) {
                const res = await this.eventsManagerService.updateChainEvent({ ...this.event, event });
                newEventId = String(res?.id);
                this.errors = res.error;
            } else {
                this.errors = await this.eventsManagerService.updateEvent({ ...this.event, event });
            }
        } else {
            this.errors = await this.eventsManagerService.addEvent(event);
        }

        if (!this.errors.length) {
            this.closeModal(newEventId, true);
        }
    }

    async validateEvent(event: EventsModel) {
        const res = await this.eventsManagerService.validateEvent(event);
        this.errors = res;
        return res;
    }

    closeModal(newEventId: string, afterEditAdd: boolean = false) {
        const targetName = this.$route.name!.split('.');
        const prevState = targetName.pop();
        if (prevState === 'edit' && this.$route.params.eventId && afterEditAdd) {
            targetName.push('view');
        }
        if (newEventId) {
            this.$route.params.eventId = newEventId;
        }
        this.$router.push({
            name: targetName.join('.'),
            params: {
                ...this.$route.params,
            },
        });
    }
}
