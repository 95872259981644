










import IDayEvent from '@/modules/events/interfaces/day-event.interface';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import EVENT_TYPE_SETTINGS from '@/modules/events/constants/event-types-settings.constant';
import EventTypeIcon from '@/modules/common/components/ui-kit/event-type-icon.vue';
import Flag from '@/modules/common/components/ui-kit/flag.vue';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';

@Component({
    components: {
        Flag,
        EventTypeIcon,
    },
})

export default class ViewEventTitle extends Vue {
    @Inject(EventsManagerServiceS) private eventsManagerService!: EventsManagerService;

    @Prop({ required: false })
    private eventId?: string;

    event: IDayEvent | null = null;

    async mounted() {
        await new Promise<void>(res => setTimeout(res, 500));
        this.event = this.eventId ? await this.eventsManagerService.getDayEventByIdFromCacheOrFromApi(this.eventId) : null;
    }
}
