































































































































import {
    Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import moment from 'moment';
import { Inject } from 'inversify-props';
import { $enum } from 'ts-enum-util';
import { ValidationError } from 'class-validator';
import {
    DatePicker, Dialog, Input, Option, Select,
} from 'element-ui';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import IDayEvent from '@/modules/events/interfaces/day-event.interface';
import EVENT_TYPE_SETTINGS from '@/modules/events/constants/event-types-settings.constant';
import EVENT_PRIVACY from '@/modules/events/constants/event-privacy.constant';
import EventsModel from '@/modules/events/models/events.model';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import EventTypeIcon from '@/modules/common/components/ui-kit/event-type-icon.vue';
import ValidationErrorComponent from '@/modules/common/components/ui-kit/validation-error.vue';
import _ from 'lodash';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import ConfirmPopup from '@/modules/common/components/popup-confirm.vue';

interface Item {
    value: number | string;
    name: string;
}

@Component({
    components: {
        EventTypeIcon,
        ValidationErrorComponent,
        CustomSelect,
        'el-dialog': Dialog,
        'el-date-picker': DatePicker,
        'el-input': Input,
        'el-select': Select,
        'el-option': Option,
        ConfirmPopup,
    },
})

export default class AddEditEventForm extends Vue {
    @Inject(EventsManagerServiceS) private eventsManagerService!: EventsManagerService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;

    @Prop({ required: false, default: null, type: Object })
    private event!: IDayEvent | null;
    private isChainHoliday: boolean = false;
    @Watch('event', { immediate: true })
    onEventChanged(event: IDayEvent) {
        if (event) {
            this.editableEvent = _.cloneDeep(event.event);
        }
    }

    @Watch('editableEvent.numberOfGuests', { immediate: true })
    onNumberOfGuestsChange(numberOfGuests: number | null) {
        if (numberOfGuests) {
            this.editableEvent.numberOfGuests = Number(numberOfGuests);
        }
    }

    @Prop({ required: true })
    errors!: ValidationError[];

    editableEvent: EventsModel = new EventsModel();

    isConfirmPopupShown: boolean = false;

    mounted(): void {
        // TODO: needs to load compsets before creating of event, need fix it.
        const { currentCompset } = this.compsetsService;
        this.isChainHoliday = Boolean(this.event?.event && !this.event?.event.marketId);
    }

    get startEndDates(): Date[] {
        const dates: Date[] = [];
        if (this.editableEvent.startDate) {
            dates.push(new Date(moment.utc(this.editableEvent.startDate).format('YYYY-MM-DD')!));
        }
        if (this.editableEvent.endDate) {
            dates.push(new Date(moment.utc(this.editableEvent.endDate).format('YYYY-MM-DD')!));
        }
        return dates;
    }

    set startEndDates(value: Date[]) {
        if (value) {
            const [startDate, endDate] = value;
            this.editableEvent.startDate = startDate;
            this.editableEvent.endDate = endDate;
        } else {
            this.editableEvent.startDate = null;
            this.editableEvent.endDate = null;
        }
    }

    get options(): Item[] {
        return $enum(EVENT_TYPE_SETTINGS).map((value): Item => ({
            value,
            name: this.$t(`eventsSettings.${value}`).toString(),
        }));
    }

    get countryItems() {
        return this.carsFiltersService.countryCodes && this.carsFiltersService.countryCodes.map(item => ({
            name: item.name,
            code: item.code.toLowerCase(),
        })) || [];
    }

    get privacy() {
        if (!this.userService.isChainOrClusterUser && !this.userService.isViewAsChain) return null;
        return $enum(EVENT_PRIVACY).map(value => ({
            value,
            name: this.$t(`privacy.${value}`).toString(),
        }));
    }

    async saveEvent() {
        if (!this.isChainHoliday) {
            this.$emit('saveEvent', this.editableEvent);
        } else {
            await this.$emit('validateEvent', this.editableEvent);
            const validation = await this.eventsManagerService.validateEvent(this.editableEvent);
            if (!validation.length) {
                this.isConfirmPopupShown = true;
            }
        }
    }

    handleDeleteAllAlerts() {
        this.$emit('saveEvent', this.editableEvent);
    }

    confirmPopupShown() {
        this.isConfirmPopupShown = !this.isConfirmPopupShown;
    }

    closeModal() {
        this.$emit('close');
    }
}
